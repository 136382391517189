body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  background-color: rgb(122, 121, 185) !important;
  height: 100% !important;
  min-height: 100vh !important;
  max-width: 100% !important;
  width: 100% !important;
  display: grid !important;
  grid-template: 0.1fr 5fr 0.5fr/ 1fr 9fr;
  grid-gap: 5px;
  gap: 5px;
  grid-template-areas:
    "logo nav"
    "sidebar content"
    "footer footer";
}

.logo {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(88, 88, 88, 0));
  grid-area: logo;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  min-width: 300px;
  outline-color: rgba(255, 255, 255, 0.171);
  outline-width: 5px;
  outline-style: inset;
}

.nav {
  background-color: rgba(0, 0, 0, 0.158);
  grid-area: nav;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  min-height: 100 !important;
  max-height: 100px;
  grid-gap: 10px;
  gap: 10px;
}

.sidebar {
  background-color: rgba(0, 0, 0, 0.5);
  grid-area: sidebar;
  min-width: 200px;
}

.content {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.274));
  grid-area: content;
  min-height: 1000px;
  padding: 10px;
}

.footer {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(88, 88, 88, 0));
  grid-area: footer;
}

.btn-nav-default {
  font-size: 30px !important;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.151), rgba(88, 88, 88, 0), rgba(252, 250, 250, 0.452)) !important;
  color: rgba(255, 255, 255, 0.548) !important;
  min-height: 100px !important;
  outline-color: rgba(255, 255, 255, 0.171) !important;
  outline-width: 3px !important;
  outline-style: inset !important;
}

.nav.nav-pills .nav-link.active {
  background-color: black;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.151), rgba(88, 88, 88, 0), rgba(252, 250, 250, 0.452)) !important;
  color: white;
}

